import React, { useEffect } from 'react'
import WebContainer from '../components/webcontainer'
import SocialProof from '../components/socialproof'
import WhatIsCb from '../components/whatiscb'
import PrivacyFirst from '../components/privacyfirst'
import ForEnterprise from '../components/forenterprise'
import { Spin } from 'antd'
import shareImage from '../images/sync_share_image.png'
import {
  Loading3QuartersOutlined,

} from '@ant-design/icons'

import Logo from '../images/svg/new_CB_logo.svg'

import LogoLight from '../images/svg/logo-light.svg'

import Shield from '../images/svg/bg-shield.svg'
import Hero1 from '../images/hero1-sshot.png'

import HeroAnimated from '../images/svg/full_animation.svg'
import Brands from '../images/brands.png'
import Scheduler from '../images/svg/scheduler-brand.svg'
import Sync from '../images/svg/sync-brand.svg'
import SEO from '../components/SEO'
import { Link } from 'gatsby'
import { getAuthState } from '../utils/auth'

import WebsiteFooter from '../components/WebsiteFooter'

import Highlight from '../components/Highlight'
import WebsiteHeader from '../components/WebsiteHeader'
const Index = ({ data }) => {
  const authState = getAuthState()
  useEffect(() => {
    // Your condition or logic for redirection
  //  if(window.location.host.includes("app")) {
    window.location.href = ('/dashboard'); // Redirect to the desired route
  //  }
   
    
}, []);
  // <div className="relative">
  //       <span className="absolute">{label}</span>
  //       <div className={`mt-9 w-${w} h-4 bg-${color}`}></div>
  //     </div>

  const Section = ({ title, content }) => {
    return (
      <div
        className="flex flex-col lg:flex-row  justify-center w-full mb-0 lg:mb-8 "
        style={{ maxWidth: 1200, zIndex: 1 }}
      >
        <div className="flex justify-center lg:justify-start lg:w-1/3 w-full ">
          <div className="font-lato text-brand_dark_blue text-5xl flex flex-row  mt-4 lg:mb-0 mb-12">
            {title}
          </div>
        </div>
        <div className="flex lg:w-2/3 w-full justify-center  lg:justify-start">
          {content}
        </div>
      </div>
    )
  }

  // const graphqlResult = useStaticQuery(graphql/* GraphQL */ `
  //   query WpPosts {
  //     # Query all WordPress blog posts sorted by date
  //     allWpPost(sort: { fields: [date], order: DESC }) {
  //       edges {
  //         previous {
  //           id
  //         }

  //         # note: this is a GraphQL alias. It renames "node" to "post" for this query
  //         # We're doing this because this "node" is a post! It makes our code more readable further down the line.
  //         post: node {
  //           id
  //           uri
  //           date(formatString: "MMMM DD, YYYY")
  //           title
  //           excerpt
  //         }

  //         next {
  //           id
  //         }
  //       }
  //     }
  //   }
  // `)

  function renderWhatIs() {
    return (
      <div className="lg:ml-2 h-full flex flex-col justify-center ">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="w-2/3 lg:w-2/4 lg:mr-8">
            <div className="mb-4">
              <h2 className="font-lato text-lg text-brand_dark_blue">Sync Calendars Together in Real-Time</h2>
            </div>
            <div className="font-roboto  text-lg mb-4">
              CalendarBridge is the only cloud-based solution that syncs
              calendars in real-time and supports Google Calendar and Microsoft
              365. Merge availability of two or more Google calendars, Outlook calendars, or a combination of Google and Outlook calendars.
            </div>
            <Link
              to="/sync"
              className="cursor-pointer font-roboto underline text-brand_blue hover:text-brand_dark_blue"
            >
              Learn More About Sync
            </Link>
          </div>
          <div className="flex justify-center lg:w-1/2">
            <Sync className="w-2/3 lg:w-3/4"></Sync>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          <div className="flex justify-center lg:justify-start lg:w-1/2 order-2 lg:order-1">
            <Scheduler className="w-2/3 lg:w-3/4"></Scheduler>
          </div>
          <div className="w-2/3 lg:w-1/2 ml-8 order-1 lg:order-2">
            <div className="font-lato lg:text-lg text-brand_dark_blue mb-4">
            <h2 className="font-lato text-lg text-brand_dark_blue">Schedule Meetings Based on Multiple Calendars</h2>
            </div>
            <div className="font-roboto mb-4">
              Schedule meetings by simply sharing a link to your availability.
              Our schedulers natively support checking availability on up to 4 calendars. When combined with syncing, each scheduler can account for even more calendars.
            </div>
            <Link
              to="/schedule"
              className="cursor-pointer font-roboto underline text-brand_blue hover:text-brand_dark_blue"
            >
              Learn More About Scheduler
            </Link>
          </div>
        </div>
      </div>
    )
  }
  const isBrowser = typeof document !== 'undefined'

  function stripHtml(html) {
    // Create a new div element
    if (isBrowser) {
      let temporalDivEl = document.createElement('div')
      // Set HTML content using provider
      temporalDivEl.innerHTML = html
      // Get the text property of the element (browser support)
      return temporalDivEl.textContent || temporalDivEl.innerText || ''
    } else {
      return ''
    }
  }

  const SectionHero = ({}) => {
    return (
      <div
        className="flex flex-col lg:flex-row items-center justify-start h-full  w-full "
        style={{ maxWidth: 1200 }}
      >
        <div className="w-full lg:w-1/3 text-white  flex flex-col lg:order-1 order-2">
          <div className="flex font-lato text-brand_dark_blue text-5xl lg:text-5xl space-x-1 lg:space-x-0 lg:flex-col  mb-4 justify-center lg:justify-start">
            <div className="flex flex-row">
              Protect Your <br /> Time & Privacy
            </div>
          </div>
          <div className="flex flex-col ">
            <div className="text-text_website text-md lg:w-90 text-justify pl-4 pr-4 lg:pl-0 lg:pr-0 lg:text-left font-roboto">
              CalendarBridge provides{' '}
              <strong>
                automatic, real-time Outlook & Google calendar syncing
              </strong>{' '}
              with advanced privacy controls. All plans also include scheduling links{' '}
              <strong>built for people with multiple calendars</strong>.
            </div>

            <div className="flex justify-center lg:justify-start lg:w-80">
              <Link
                className="text-md text-center rounded-md pl-4 pr-4 pt-2 pb-2 mt-6 mb-5 hover:bg-burnt bg-dark_burnt text-white lg:w-80"
                to="/signup?referral=homepage"
              >
                Try CalendarBridge For Free
              </Link>
            </div>
            <div className="text-text_website text-md text-center lg:text-left font-roboto">
              Already a user? <Link to="/dashboard">Access your dashboard</Link>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center lg:w-2/3 lg:order-2 order-1 lg:h-96">
          <div>
            <HeroAnimated width="100%" height="100%"></HeroAnimated>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <SEO
        title="CalendarBridge | Sync All Your Calendars"
        description="Welcome to the only cloud-based solution that syncs digital calendars in real-time and supports Google Calendar and Microsoft 365."
        image={shareImage}
      />
      <div className="flex flex-col justify-center items-center text-sm gap-4 min-h-screen">
          <Spin
                  indicator={
                    <Loading3QuartersOutlined
                      spin
                      style={{ fontSize: '100px' }}
                    />
                  }
                />
                <a href="/dashboard">Continue</a>
        </div>
    </div>
  )
}

export default Index

const styles = {
  heroHeading: {
    marginBottom: '0.6em',
    fontSize: '1.5em',
    color: '#09467D',
  },
  card: {
    backgroundColor: 'white',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    margin: '8px 8px',
    padding: '30px 20px 100px 20px',
    borderRadius: '5px',
  },
}
